import { tradeConstants } from '../_constants';

const initState = {
    loading: false,
    requests:
    {
        content: []
    },
    error: null
};

export function trades ( state = initState, action )
{
    switch ( action.type )
    {
        case tradeConstants.UPDATE_TRADE_REQUEST:
        case tradeConstants.DELETE_TRADE_REQUEST:
        case tradeConstants.CREATE_TRADE_REQUEST:
        case tradeConstants.GETALL_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case tradeConstants.CREATE_TRADE_FAILURE:
        case tradeConstants.GETALL_FAILURE:
        case tradeConstants.UPDATE_TRADE_FAILURE:
        case tradeConstants.DELETE_TRADE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case tradeConstants.CREATE_TRADE_SUCCESS:
            const trades = [ action.trade, ...state.requests.content ].map( ( item, i ) =>
            {
                const sn = i + 1;
                return { ...item, sn };
            } );

            return {
                ...state,
                loading: false,
                error: null,
                requests: { ...state.requests, content: trades },
            };

        case tradeConstants.GETALL_SUCCESS:
            const req = { ...action.trades };

            const start = state.requests.content.length;

            req.content = req.content.map( ( item, i ) =>
            {
                const sn = i + 1 + start;
                return { ...item, sn };
            } );

            req.pageable.pageNumber += 1;

            if ( req.first )
            {

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };


            }
            else
            {
                req.content = [ ...state.requests.content, ...req.content ];

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };
            }

        // return {
        //     ...state,
        //     loading: false,
        //     error: null,
        //     tradePairs: [ ...action.trades ],

        // };

        case tradeConstants.UPDATE_TRADE_SUCCESS:
            const content = [ ...state.requests.content ];

            const index = content.findIndex( t => t.id === action.trade.id );
            content[ index ] = { ...action.trade, sn: index + 1 };

            return {
                ...state,
                loading: false,
                requests: { ...state.requests, content },
                error: null,

            };

        case tradeConstants.DELETE_TRADE_SUCCESS:
            const updatedTrades = [ ...state.requests.content ]
                .filter( t => t.id !== action.id )
                .map( ( item, i ) => 
                {
                    return { ...item, sn: i + 1 };
                } );

            return {
                ...state,
                loading: false,
                requests: { ...state.requests, content: updatedTrades },
                error: null,

            };

        default:
            return state;
    }
}
