import { tradeConstants } from '../_constants';
import { tradeService } from '../_services';

function createTrade ( trade )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const res = await tradeService.create( trade );
            dispatch( success( res ) );
        } catch ( error )
        {
            dispatch( failure( error.toString() ) );
        }
    };

    function request ()
    {
        return { type: tradeConstants.CREATE_TRADE_REQUEST };
    }

    function success ( trade )
    {
        return { type: tradeConstants.CREATE_TRADE_SUCCESS, trade };
    }

    function failure ( error )
    {
        return { type: tradeConstants.CREATE_TRADE_FAILURE, error };
    }
}


function getAll ( page )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const trades = await tradeService.getAll( page );
            dispatch( success( trades ) );
        } catch ( e )
        {
            dispatch( failure( e.toString() ) );
        }

    };

    function request ()
    {
        return { type: tradeConstants.GETALL_REQUEST };
    }
    function success ( trades )
    {
        return { type: tradeConstants.GETALL_SUCCESS, trades };
    }
    function failure ( error )
    {
        return { type: tradeConstants.GETALL_FAILURE, error };
    }
}

function updateTrade ( tradeObj )
{

    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const updatedTade = await tradeService.update( tradeObj );

            dispatch( success( updatedTade ) );
        } catch ( error )
        {
            dispatch( failure( error.toString() ) );
        }
    };

    function request ()
    {
        return { type: tradeConstants.UPDATE_TRADE_REQUEST };
    }

    function success ( trade )
    {
        return { type: tradeConstants.UPDATE_TRADE_SUCCESS, trade };
    }

    function failure ( error )
    {
        return { type: tradeConstants.UPDATE_TRADE_FAILURE, error };
    }

}

function _delete ( id )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            await tradeService._delete( id );
            dispatch( success( id ) );
        }
        catch ( error )
        {
            dispatch( failure( error.toString() ) );
        }
    };

    function request () { return { type: tradeConstants.DELETE_TRADE_REQUEST }; }
    function success ( id ) { return { type: tradeConstants.DELETE_TRADE_SUCCESS, id }; }
    function failure ( error ) { return { type: tradeConstants.DELETE_TRADE_FAILURE, error }; }

}




export const tradeActions = {
    createTrade,
    getAll,
    updateTrade,
    _delete
};
