
function logout ()
{
    // remove user from local storage to log user out
    localStorage.removeItem( 'user' );
}
export const handleResponse = ( response ) =>
{

    return response.text().then( text =>
    {
        const data = text && JSON.parse( text );
        //  console.log(response);
        if ( !response.ok )
        {
            if ( response.status === 401 )
            {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload();
            }
            const error = ( data && data.message ) ? data.message : "Error Occured";
            return Promise.reject( error );
        }
        return data;
    } )
        .catch( error => 
        {
            console.log( error );
            return Promise.reject( error );
        } );
};
