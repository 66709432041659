import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';

const mode =
{
    all: "ALL",
    failed: "FAILED",
    successful: "SUCCESSFUL"

};


function getTransactions ( status ) 
{
    return async dispatch =>
    {
        try
        {
            dispatch( request( status ) );
            const req = await dashboardService.getTransactions( status );
            dispatch( success( req, status ) );
        }
        catch ( e )
        {
            dispatch( failure( e.toString(), status ) );
        }
    };

    function request ( status )
    {
        const type = dashboardConstants[ `GET_${ mode[ status ] }_TRXN_REQUEST` ];
        return { type };
    }

    function failure ( error, status )
    {
        const type = dashboardConstants[ `GET_${ mode[ status ] }_TRXN_FAIL` ];
        return { type, error };

    }


    function success ( data, status )
    {
        const type = dashboardConstants[ `GET_${ mode[ status ] }_TRXN_SUCCESS` ];

        return { type, data, status };
    }

}

export const dashboardActions =
{
    getTransactions
};
