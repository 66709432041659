//import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import LoginForm from './components/Auth/LoginForm';
import PrivateRoute from './components/PrivateRoute';
import React from 'react';
import { history } from './_helpers';
import componentQueries from 'react-component-queries';
import { Router, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';

/*
const AlertPage = React.lazy( () => import( './pages/AlertPage' ) );
const AuthModalPage = React.lazy( () => import( './pages/AuthModalPage' ) );
const BadgePage = React.lazy( () => import( './pages/BadgePage' ) );
const ModalPage = React.lazy( () => import( './pages/ModalPage' ) );
*/
const DashboardPage = React.lazy( () => import( './pages/DashboardPage' ) );
const TradePairsPage = React.lazy( () => import( './pages/TradePairsPage' ) );
const UsersPage = React.lazy( () => import( './pages/UsersPage' ) );
//const SettingsPage = React.lazy( () => import( './pages/SettingsPage' ) );
const OrdersPage = React.lazy( () => import( './pages/OrdersPage' ) );
const BuyRequestPage = React.lazy( () => import( './pages/BuyRequest' ) );
const SellRequestPage = React.lazy( () => import( "./pages/SellRequest" ) );
const CustomerPage = React.lazy( () => import( "./pages/CustomerPage" ) );
const OrderDetails = React.lazy( () => import( './pages/OrderDetails' ) );
const CustomerOrdersPage = React.lazy( () => import( './pages/CustomerOrdersPage' ) );

class App extends React.Component
{
  render ()
  {
    return (
      <Router history={ history }>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={ EmptyLayout }
              component={ props => (
                <LoginForm{ ...props } />
              ) }
            />
            {/* <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            /> */}

            <MainLayout breakpoint={ this.props.breakpoint }>
              <React.Suspense fallback={ <PageSpinner /> }>

                <PrivateRoute exact path="/" component={ DashboardPage } />
                <PrivateRoute exact path="/orders" component={ OrdersPage } />
                <PrivateRoute exact path="/customer-orders/:id" component={ CustomerOrdersPage } />
                <PrivateRoute exact path="/orders/:id" component={ OrderDetails } />
                <PrivateRoute exact path="/trade-pairs" component={ TradePairsPage } />
                <PrivateRoute exact path="/users" component={ UsersPage } />
                <PrivateRoute exact path="/buy-request" component={ BuyRequestPage } />
                <PrivateRoute exact path="/sell-request" component={ SellRequestPage } />
                <PrivateRoute exact path="/customer" component={ CustomerPage } />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </Router>
    );
  }
}

const query = ( { width } ) =>
{
  if ( width < 575 )
  {
    return { breakpoint: 'xs' };
  }

  if ( 576 < width && width < 767 )
  {
    return { breakpoint: 'sm' };
  }

  if ( 768 < width && width < 991 )
  {
    return { breakpoint: 'md' };
  }

  if ( 992 < width && width < 1199 )
  {
    return { breakpoint: 'lg' };
  }

  if ( width > 1200 )
  {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries( query )( App );
