export const sellRequestConstants =
{
    GETALL_REQUEST: "GETALL_SELL_REQUESTS",
    GETALL_SUCCESS: "GETALL_SELL_SUCCESS",
    GETALL_FAILURE: "GETALL_SELL_FAILURE",

    GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
    GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
    GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

    GET_BY_REF_ID_REQUEST: "GET_BY_REF_ID_REQUEST",
    GET_BY_REF_ID_SUCCESS: "GET_BY_REF_ID_SUCCESS",
    GET_BY_REF_ID_FAILURE: "GET_BY_REF_ID_FAILURE",



};
