import { sellRequestConstants } from '../_constants';
import { sellRequestService } from '../_services';


function getAll ( page )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const requests = await sellRequestService.getAll( page );
            dispatch( success( requests ) );
        } catch ( e )
        {
            // console.log( e );
            dispatch( failure( e.toString() ) );
        }

    };

    function request ()
    {
        return { type: sellRequestConstants.GETALL_REQUEST };
    }
    function success ( requests )
    {
        return { type: sellRequestConstants.GETALL_SUCCESS, requests };
    }
    function failure ( error )
    {
        return { type: sellRequestConstants.GETALL_FAILURE, error };
    }
}



export const sellRequestsActions =
{
    getAll
};
