import { modalConstants } from '../_constants';

export const modalActions = {
    close,
    open
};

function close() {
    return { type: modalConstants.CLOSE};
}

function open() {
    return { type: modalConstants.OPEN};
}
