import { createStore, applyMiddleware } from 'redux';
//import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import /* persistedReducer, */ { rootReducer } from '../_reducers';
import { composeWithDevTools } from 'redux-devtools-extension';


const loggerMiddleware = createLogger();
//const middlewares =  [thunkMiddleware,loggerMiddleware];
export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    )
);

//export const persistor = persistStore( store );
