import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React, { useEffect/*, useRef*/ } from 'react';
/*
import
  {
    MdImportantDevices,
    // MdCardGiftcard,
    MdLoyalty,
  } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { history } from '../../_helpers';
import { alertActions } from '../../_actions';
*/
import { /*useDispatch ,*/ useSelector } from 'react-redux';


const MainLayout = ( { children, breakpoint } ) =>
{

  const alert = useSelector( state => state.alert );
  // const dispatch = useDispatch();
  // const notificationSystem = useRef();
  const isSidebarOpen = () =>
  {
    return document
      .querySelector( '.cr-sidebar' )
      .classList.contains( 'cr-sidebar--open' );
  };

  useEffect( () =>
  {
    checkBreakpoint( breakpoint );
    // history.listen((location, action) => {
    //     // clear alert on location change
    //     dispatch(alertActions.clear());
    // });
    // setTimeout(() => {
    //   if (!notificationSystem) {
    //     return;
    //   }

    //   notificationSystem.addNotification({
    //     title: <MdImportantDevices />,
    //     message: 'Welome to Reduction Admin!',
    //     level: 'info',
    //   });
    // }, 1500);

    // setTimeout(() => {
    //   if (!notificationSystem) {
    //     return;
    //   }

    //   notificationSystem.addNotification({
    //     title: <MdLoyalty />,
    //     message:
    //       'Reduction is carefully designed template powered by React and Bootstrap4!',
    //     level: 'info',
    //   });
    // }, 2500);


  }, [] );

  useEffect( () =>
  {
    checkBreakpoint( breakpoint );

  }, [ breakpoint ] );
  // close sidebar when
  const handleContentClick = event =>
  {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      isSidebarOpen() &&
      ( breakpoint === 'xs' ||
        breakpoint === 'sm' ||
        breakpoint === 'md' )
    )
    {
      openSidebar( 'close' );
    }
  };

  const checkBreakpoint = ( breakpoint ) =>
  {
    switch ( breakpoint )
    {
      case 'xs':
      case 'sm':
      case 'md':
        openSidebar( 'close' );
        break;
      case 'lg':
      case 'xl':
      default:
        openSidebar( 'open' );
    }
  };

  const openSidebar = ( openOrClose ) =>
  {
    if ( openOrClose === 'open' )
    {
      return document
        .querySelector( '.cr-sidebar' )
        .classList.add( 'cr-sidebar--open' );
    }
    document.querySelector( '.cr-sidebar' ).classList.remove( 'cr-sidebar--open' );
  };

  return (
    <main className="cr-app bg-light">
      <Sidebar />
      <Content fluid onClick={ handleContentClick }>
        <Header />
        { alert.message &&
          <div className={ `alert ${ alert.type }` }>{ alert.message }</div>
        }
        { children }
        <Footer />
      </Content>

      {/* <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        /> */}
    </main>
  );

};


export default MainLayout;
