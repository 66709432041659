import { userConstants } from '../_constants';

let user = JSON.parse( localStorage.getItem( 'user' ) );
const initialState = user ? { loggedIn: true, user } : { user: { username: "" }, loggingIn: false };

export function authentication ( state = initialState, action )
{
  switch ( action.type )
  {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return { user: { username: "" }, loggingIn: false };
    case userConstants.LOGOUT:
      return { user: { username: "" }, loggingIn: false };
    default:
      return state;
  }
}
