import { customerOrdersConstants } from '../_constants';
import { customerOrdersService } from '../_services';

function getAll ( page, id ) 
{
    return async dispatch =>
    {
        if ( id )
        {

            try
            {
                dispatch( request() );
                const requests = await customerOrdersService.getAll( page, id );
                dispatch( success( requests ) );
            }
            catch ( e )
            {
                // console.log( e );
                dispatch( failure( e.toString() ) );
            }
        }
    };

    function request ()
    {
        return { type: customerOrdersConstants.GET_ALL_BY_ID_REQUEST };
    }
    function success ( requests )
    {
        return { type: customerOrdersConstants.GET_ALL_BY_ID_SUCCESS, requests };
    }
    function failure ( error )
    {
        return { type: customerOrdersConstants.GET_ALL_BY_ID_FAILURE, error };
    }
}

function setUserId ( id ) 
{
    return { type: customerOrdersConstants.SET_ID, id };
}

export const customerOrdersActions =
{
    getAll,
    setUserId
};
