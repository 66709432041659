import { ordersConstants } from '../_constants';

const initState =
{
    requests:
    {
        content: []
    }
};

export function orders ( state = initState, action )
{
    switch ( action.type ) 
    {
        case ordersConstants.GETALL_REQUEST:
        case ordersConstants.GET_BY_ID_REQUEST:
        case ordersConstants.GET_BY_REF_ID_REQUEST:
        case ordersConstants.SEND_CRYPTO_REQUEST:
        case ordersConstants.UPDATE_PAYMENT_REQUEST:
        case ordersConstants.CREATE_ORDER_REQUEST:
        case ordersConstants.PAY_FIAT_REQUEST:
        case ordersConstants.CONFIRM_DEPOSIT_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case ordersConstants.GETALL_FAILURE:
        case ordersConstants.GET_BY_ID_FAILURE:
        case ordersConstants.SEND_CRYPTO_FAILURE:
        case ordersConstants.UPDATE_PAYMENT_FAILURE:
        case ordersConstants.GET_BY_REF_ID_FAILURE:
        case ordersConstants.CREATE_ORDER_FAILURE:
        case ordersConstants.PAY_FIAT_FAILURE:
        case ordersConstants.CONFIRM_DEPOSIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case ordersConstants.GETALL_SUCCESS:

            const req = { ...action.requests };

            const start = state.requests.content.length;

            req.content = req.content.map( ( item, i ) =>
            {
                const sn = i + 1 + start;
                return { ...item, sn };
            } );

            req.pageable.pageNumber += 1;

            if ( req.first )
            {

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };


            }
            else
            {

                req.content = [ ...state.requests.content, ...req.content ];

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };

            }

        case ordersConstants.CREATE_ORDER_SUCCESS:

            const orders = [ action.order, ...state.requests.content ].map( ( item, i ) =>
            {
                const sn = i + 1;
                return { ...item, sn };
            } );

            return {
                ...state,
                loading: false,
                error: null,
                requests: { ...state.requests, content: orders },
            };

        case ordersConstants.UPDATE_PAYMENT_SUCCESS:
        case ordersConstants.CONFIRM_DEPOSIT_SUCCESS:
        case ordersConstants.PAY_FIAT_SUCCESS:

            const content = [ ...state.requests.content ];
            const orderIndex = content.findIndex( o => o.id === action.order.id );


            content[ orderIndex ] = orderIndex > -1 ?
                {
                    ...action.order,
                    sn: content[ orderIndex ].sn
                }
                : content[ orderIndex ];

            return {
                ...state,
                loading: false,
                error: null,
                requests: { ...state.requests, content },
            };


        case ordersConstants.SEND_CRYPTO_SUCCESS:
            const c = [ ...state.requests.content ];
            const i = c.findIndex( o => o.id === action.orderId );

            c[ i ] = { ...c[ i ], status: "SENDING" };

            return {
                ...state,
                loading: false,
                error: null,
                requests: { ...state.requests, content: c },
            };


        default:
            return state;
    }
}
