import logo from 'assets/img/logo/login-logo.png';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { Button, Form, FormGroup, Input, Label, Col, Row, Card, Alert } from 'reactstrap';
import
{
  FaEye,
  FaEyeSlash
} from 'react-icons/fa';

function LoginForm ( props )
{
  const [ showP, setShowP ] = useState( false );
  const { users } = useSelector( state => state );

  const [ inputs, setInputs ] = useState( {
    username: '',
    password: ''
  } );
  const [ submitted, setSubmitted ] = useState( false );
  const { username, password } = inputs;
  // const loggingIn = useSelector( state => state.authentication.loggingIn );
  const dispatch = useDispatch();

  useEffect( () =>
  {
    dispatch( userActions.logout() );
  }, [] );

  const handleChange = ( e ) =>
  {

    const { name, value } = e.target;
    setInputs( inputs => ( { ...inputs, [ name ]: value } ) );
  };

  const handleSubmit = event =>
  {
    event.preventDefault();
    setSubmitted( true );
    if ( username && password )
    {
      dispatch( userActions.login( username, password ) );
    }
  };
  const onLogoClick = () =>
  {
    props.history.push( '/' );
  };


  const {
    showLogo,
    children
  } = props;

  return (
    <Row
      style={ {
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      } }>
      <Col md={ 6 } lg={ 4 }>
        <Card body>
          <Form onSubmit={ handleSubmit }>
            { showLogo && (
              <div className="text-center login-logo">
                <img
                  src={ logo }
                  className="rounded"
                  style={ { width: 60, height: 60, cursor: 'pointer' } }
                  alt="logo"
                  onClick={ onLogoClick }
                />
              </div>
            ) }

            {
              users.error &&
              <Alert color="danger"> { users.error } </Alert>
            }

            <FormGroup>
              <Label for="Username">Username</Label>
              <Input type="text" name="username" value={ username } onChange={ handleChange } className={ 'form-control' + ( submitted && !username ? ' is-invalid' : '' ) } />
              { submitted && !username &&
                <div className="invalid-feedback">Username is required</div>
              }
            </FormGroup>
            <FormGroup className="passwordDiv">
              <Label for="Password">Password</Label>
              <Input type={ showP ? "text" : "password" } name="password" value={ password } onChange={ handleChange } className={ 'form-control' + ( submitted && !password ? ' is-invalid' : '' ) } />
              { submitted && !password &&
                <div className="invalid-feedback">Password is required</div>
              }
              <Button className="showPassword" onClick={ () => setShowP( !showP ) }>
                { showP ? <FaEye /> : <FaEyeSlash /> }
              </Button>

            </FormGroup>
            {/* <FormGroup check>
              <Label check>
                <Input type="checkbox" />{' '}
                Remember me
          </Label>
            </FormGroup> 
            <hr />*/

            }
            <br />
            <Button
              size="lg"
              color="primary"
              className="border-0"
              block
              disabled={ !( username && password ) }
              onClick={ handleSubmit }>
              Login
        </Button>
            { children }
          </Form>
        </Card>
      </Col>
    </Row>

  );
}


export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

LoginForm.propTypes = {
  authState: PropTypes.oneOf( [ STATE_LOGIN, STATE_SIGNUP ] ).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

LoginForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => { },
};

export default LoginForm;
