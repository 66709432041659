import { dashboardConstants } from '../_constants';

const initState =
{
    all: null,
    failed: null,
    successfull: null,
    loading: false,
    error: null

};

export function dashboard ( state = initState, action ) 
{
    switch ( action.type ) 
    {

        case dashboardConstants.GET_ALL_TRXN_REQUEST:
        case dashboardConstants.GET_FAILED_TRXN_REQUEST:
        case dashboardConstants.GET_SUCCESSFUL_TRXN_REQUEST:
            return {
                ...state,
                error: null,
                loading: true
            };


        case dashboardConstants.GET_ALL_TRXN_FAIL:
        case dashboardConstants.GET_FAILED_TRXN_FAIL:
        case dashboardConstants.GET_SUCCESSFUL_TRXN_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false
            };


        case dashboardConstants.GET_ALL_TRXN_SUCCESS:
        case dashboardConstants.GET_FAILED_TRXN_SUCCESS:
        case dashboardConstants.GET_SUCCESSFUL_TRXN_SUCCESS:

            return {
                ...state,
                error: action.error,
                loading: false,
                [ action.status ]: action.data
            };

        default:
            return state;
    }
}
