import { ordersConstants } from '../_constants';
import { ordersService } from '../_services';


function getAll ( page )
{

    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const requests = await ordersService.getAll( page );
            dispatch( success( requests ) );
        } catch ( e )
        {
            // console.log( e );
            dispatch( failure( e.toString() ) );
        }

    };

    function request ()
    {
        return { type: ordersConstants.GETALL_REQUEST };
    }
    function success ( requests )
    {
        return { type: ordersConstants.GETALL_SUCCESS, requests };
    }
    function failure ( error )
    {
        return { type: ordersConstants.GETALL_FAILURE, error };
    }

}

function createOrder ( order ) 
{
    return async dispatch =>
    {
        try 
        {
            dispatch( request() );
            const res = await ordersService.createOrder( order );
            dispatch( success( res ) );

        }
        catch ( error ) 
        {
            dispatch( failure( error.toString() ) );
        }
    };



    function request ()
    {
        return { type: ordersConstants.CREATE_ORDER_REQUEST };
    }

    function success ( order )
    {
        return { type: ordersConstants.CREATE_ORDER_SUCCESS, order };
    }

    function failure ( error )
    {
        return { type: ordersConstants.CREATE_ORDER_FAILURE, error };
    }
}

function sendCrypto ( orderId )
{
    if ( orderId )
    {
        return async dispatch =>
        {
            try 
            {
                dispatch( request() );
                await ordersService.sendCrypto( orderId );
                dispatch( success( orderId ) );
            }
            catch ( error ) 
            {
                dispatch( failure( error.toString() ) );
            }
        };

        function request ()
        {
            return { type: ordersConstants.SEND_CRYPTO_REQUEST };
        }

        function success ( orderId )
        {
            return { type: ordersConstants.SEND_CRYPTO_SUCCESS, orderId };
        }

        function failure ( error )
        {
            return { type: ordersConstants.SEND_CRYPTO_FAILURE, error };
        }
    }
}

function updatePayment ( orderId, updatePaymentRequest ) 
{
    if ( orderId )
    {

        return async dispatch =>
        {
            try 
            {
                dispatch( request() );
                const res = await ordersService.updatePayment( orderId, updatePaymentRequest );
                dispatch( success( res ) );
            }
            catch ( error ) 
            {
                dispatch( failure( error.toString() ) );
            }
        };

        function request ()
        {
            return { type: ordersConstants.UPDATE_PAYMENT_REQUEST };
        }

        function success ( order )
        {
            return { type: ordersConstants.UPDATE_PAYMENT_SUCCESS, order };
        }

        function failure ( error )
        {
            return { type: ordersConstants.UPDATE_PAYMENT_FAILURE, error };
        }
    }

}

function confirmDeposit ( order ) 
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const resp = await ordersService.confirmDeposit( order );
            dispatch( success( resp ) );
        } catch ( e )
        {
            // console.log( e );
            dispatch( failure( e.toString() ) );
        }

    };

    function request ()
    {
        return { type: ordersConstants.CONFIRM_DEPOSIT_REQUEST };
    }
    function success ( order )
    {
        return { type: ordersConstants.CONFIRM_DEPOSIT_SUCCESS, order };
    }
    function failure ( error )
    {
        return { type: ordersConstants.CONFIRM_DEPOSIT_FAILURE, error };
    }
}

function payFiat ( orderId )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const resp = await ordersService.payFiat( orderId );
            dispatch( success( resp ) );
        } catch ( e )
        {
            // console.log( e );
            dispatch( failure( e.toString() ) );
        }

    };

    function request ()
    {
        return { type: ordersConstants.PAY_FIAT_REQUEST };
    }
    function success ( order )
    {
        return { type: ordersConstants.PAY_FIAT_SUCCESS, order };
    }
    function failure ( error )
    {
        return { type: ordersConstants.PAY_FIAT_FAILURE, error };
    }
}


export const ordersActions =
{
    getAll,
    createOrder,
    sendCrypto,
    updatePayment,
    payFiat,
    confirmDeposit
};
