import { baseUrl, authHeader } from '../_helpers';
import { handleResponse } from './interceptors';

function create ( trade )
{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( trade ),
    };

    return fetch( `${ baseUrl }/api/admin/tradingpairs`, requestOptions ).then(
        handleResponse,
    );
}

function getAll ( page = 0 )
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch( `${ baseUrl }/api/admin/tradingpairs?page=${ page }`, requestOptions )
        .then( handleResponse )
        ;
}


function update ( trade )
{
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( trade )
    };

    return fetch( `${ baseUrl }/api/admin/tradingpairs/${ trade.id }`, requestOptions ).then( handleResponse );;
}

function getById ( id )
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch( `${ baseUrl }/admin/tradingpairs/${ id }`, requestOptions ).then( handleResponse );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete ( id )
{
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };


    return fetch( `${ baseUrl }/api/admin/tradingpairs/${ id }`, requestOptions ).then( handleResponse );
}

export const tradeService = {
    create,
    getAll,
    update,
    _delete,
    getById
};
