import React, { useState, useEffect, createContext } from 'react';

const BankContext = createContext();


function BankProvider ( { children } ) 
{
    const [ banks, setBanks ] = useState( [] );
    const [ error, setError ] = useState( null );
    const [ loading, setL ] = useState( false );

    const verifyAccount = async ( accountNumber, bankCode ) =>
    {
        try 
        {
            setL( true );
            setError( null );
            const res = await fetch( `https://api.dev.afriswap.io/api/utils/bank/verifyAccount?accountNumber=${ accountNumber }&bankCode=${ bankCode }` );

            setL( false );
            if ( res.ok )
            {
                return true;
            }
            else
            {
                setError( "Unable to verify account" );
                return false;
            }

        }
        catch ( error ) 
        {
            setError( error.message );
            return false;
        }

    };

    useEffect( () => 
    {

        const getBanks = async () =>
        {
            setError( null );
            try 
            {
                const res = await fetch( "https://api.dev.afriswap.io/api/utils/banks/ngn" );

                const bankJSON = await res.json();

                if ( bankJSON.status )
                {
                    const banks = bankJSON.data.map( bank => (
                        {
                            name: bank.name,
                            code: bank.code
                        }
                    ) );

                    setBanks( banks );
                }
            }
            catch ( error ) 
            {
                alert( "Unable to retrive banks. Please refresh page" );
                setError( error );
            }
        };

        getBanks();

    }, [] );

    return (
        <BankContext.Provider value=
            {
                {
                    verifyAccount,
                    banks,
                    error,
                    loading
                }
            }>
            {children }
        </BankContext.Provider>
    );
}

export { BankProvider, BankContext };
