import { userConstants } from '../_constants';

export function users ( state = { items: { content: [] } }, action )
{
  let items = state.items;
  switch ( action.type )
  {
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      const req = { ...action.users };

      const start = state.items.content.length;

      req.content = req.content.map( ( item, i ) =>
      {
        const sn = i + 1 + start;
        return { ...item, sn };
      } );

      req.pageable.pageNumber += 1;

      if ( req.first )
      {
        return {
          ...state,
          loading: false,
          error: null,
          items: req
        };

      }
      else
      {
        req.content = [ ...state.items.content, ...req.content ];

        return {
          ...state,
          loading: false,
          error: null,
          items: req
        };
      }

    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case userConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_SUCCESS:

      const users = [ action.user, ...state.items.content ].map( ( item, i ) =>
      {
        const sn = i + 1;
        return { ...item, sn };
      } );

      return {
        ...state,
        items: { ...state.items, content: users },
        error: null,
        loading: false,
      };
    case userConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,

        loading: false,
      };

    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_SUCCESS:

      items.content = items.content.map( ( u, index ) =>
      {
        if ( u.id === action.user.id )
        {
          u =
          {
            ...action.user,
            sn: index + 1
          };
        }
        return u;
      } );

      return {
        ...state,
        items: items,
        error: null,
        loading: false,
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      let c = [ ...state.items.content ].map( user =>
        user.id === action.id
          ? { ...user, deleting: true }
          : user
      );

      return {
        ...state,
        loading: true,
        items: { ...items, content: c }
      };
    case userConstants.DELETE_SUCCESS:
      let content = state.items.content.filter( user => user.id !== action.id );
      content = content.map( ( item, i ) => 
      {
        return { ...item, sn: i + 1 };
      } );

      return {
        ...state,
        items:
        {
          ...items,
          content
        },
        loading: false,
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        loading: false,
        items:
        {
          content: state.items.map( user =>
          {
            if ( user.id === action.id )
            {
              // make copy of user without 'deleting:true' property
              const { deleting, ...userCopy } = user;
              // return copy of user with 'deleteError:[error]' property
              return { ...userCopy, deleteError: action.error };
            }

            return user;
          } )
        }
      };

    case userConstants.LOGIN_FAILURE:
      return { ...state, error: action.error, loading: false };


    case userConstants.LOGIN_SUCCESS:
      return { ...state, error: null, loading: false };

    default:
      return state;
  }
}
