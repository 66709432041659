import { combineReducers } from 'redux';
//import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { trades } from "./trade.reducer";
import { buyRequests } from "./buyRequest.reducer";
import { sellRequests } from "./sellRequest.reducer";
import { orders } from "./orders.reducer";
import { customers } from "./customer.reducer";
import { dashboard } from "./dashboard.reducer";
import { customerOrders } from "./customerOrders.reducer";


const rootReducer = combineReducers( {
  authentication,
  registration,
  users,
  alert,
  trades,
  orders,
  buyRequests,
  sellRequests,
  customers,
  dashboard,
  customerOrders

} );

// const persistConfig = {
//   key: 'afriswapadmin',
//   storage,
//   stateReconciler: autoMergeLevel2
// };

//const persistedReducer = persistReducer( persistConfig, rootReducer );

//export default persistedReducer;

export { rootReducer };
