export const customerConstants =
{
    GETALL_REQUEST: "GETALL_CUSTOMERS_REQUESTS",
    GETALL_SUCCESS: "GETALL_CUSTOMERS_SUCCESS",
    GETALL_FAILURE: "GETALL_CUSTOMERS_FAILURE",

    GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
    GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
    GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",


};
