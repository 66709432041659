import { baseUrl } from '../_helpers';
import { authHeader } from '../_helpers';
import { handleResponse } from './interceptors';

export const userService = {
    login,
    register,
    logout,
    getAll,
    getById,
    update,
    create,
    delete: _delete
};

function logout ()
{
    // remove user from local storage to log user out
    localStorage.removeItem( 'user' );
}
function login ( username, password )
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( { username, password } )
    };

    return fetch( `${ baseUrl }/api/admin/auth/signin`, requestOptions )
        .then( handleResponse )
        ;
}

function getAll ( page = 0 )
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch( `${ baseUrl }/api/admin/users?page=${ page }`, requestOptions ).then( handleResponse );
}

function getById ( id )
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch( `${ baseUrl }/users/${ id }`, requestOptions ).then( handleResponse );
}

function register ( user )
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( user )
    };

    return fetch( `${ baseUrl }/users/register`, requestOptions ).then( handleResponse );
}

function create ( user )
{
    const requestOptions = {

        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( user )
    };

    return fetch( `${ baseUrl }/api/admin/users`, requestOptions ).then( handleResponse );
}

function update ( user )
{
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( user )
    };

    return fetch( `${ baseUrl }/api/admin/users/${ user.id }`, requestOptions ).then( handleResponse );;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete ( id )
{
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch( `${ baseUrl }/api/admin/users/${ id }`, requestOptions ).then( handleResponse );
}
