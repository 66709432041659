export const dashboardConstants =
{
    GET_ALL_TRXN_REQUEST: "GET_ALL_TRXN_REQUEST",
    GET_ALL_TRXN_SUCCESS: "GET_ALL_TRXN_SUCCESS",
    GET_ALL_TRXN_FAIL: "GET_ALL_TRXN_FAIL",

    GET_FAILED_TRXN_REQUEST: "GET_FAILED_TRXN_REQUEST",
    GET_FAILED_TRXN_SUCCESS: "GET_FAILED_TRXN_SUCCESS",
    GET_FAILED_TRXN_FAIL: "GET_FAILED_TRXN_FAIL",

    GET_SUCCESSFUL_TRXN_REQUEST: "GET_SUCCESSFUL_TRXN_REQUEST",
    GET_SUCCESSFUL_TRXN_SUCCESS: "GET_SUCCESSFUL_TRXN_SUCCESS",
    GET_SUCCESSFUL_TRXN_FAIL: "GET_SUCCESSFUL_TRXN_FAIL",
};
