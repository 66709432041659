import { baseUrl, authHeader } from '../_helpers';
import { handleResponse } from './interceptors';

function getAll ( page = 0 )
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch( `${ baseUrl }/api/admin/sellrequest?page=${ page }`, requestOptions )
        .then( handleResponse )
        ;
}
export const sellRequestService =
{
    getAll
};
