export const ordersConstants =
{
    GETALL_REQUEST: "GETALL_ORDERS_REQUESTS",
    GETALL_SUCCESS: "GETALL_ORDERS_SUCCESS",
    GETALL_FAILURE: "GETALL_ORDERS_FAILURE",

    GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
    GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
    GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

    GET_BY_REF_ID_REQUEST: "GET_BY_REF_ID_REQUEST",
    GET_BY_REF_ID_SUCCESS: "GET_BY_REF_ID_SUCCESS",
    GET_BY_REF_ID_FAILURE: "GET_BY_REF_ID_FAILURE",

    CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",

    SEND_CRYPTO_REQUEST: "SEND_CRYPTO_REQUEST",
    SEND_CRYPTO_SUCCESS: "SEND_CRYPTO_SUCCESS",
    SEND_CRYPTO_FAILURE: "SEND_CRYPTO_FAILURE",

    UPDATE_PAYMENT_REQUEST: "UPDATE_PAYMENT_REQUEST",
    UPDATE_PAYMENT_SUCCESS: "UPDATE_PAYMENT_SUCCESS",
    UPDATE_PAYMENT_FAILURE: "UPDATE_PAYMENT_FAILURE",


    CONFIRM_DEPOSIT_REQUEST: "CONFIRM_DEPOSIT_REQUEST",
    CONFIRM_DEPOSIT_SUCCESS: "CONFIRM_DEPOSIT_SUCCESS",
    CONFIRM_DEPOSIT_FAILURE: "CONFIRM_DEPOSIT_FAILURE",


    PAY_FIAT_REQUEST: "PAY_FIAT_REQUEST",
    PAY_FIAT_SUCCESS: "PAY_FIAT_SUCCESS",
    PAY_FIAT_FAILURE: "PAY_FIAT_FAILURE",

};
