import { customerOrdersConstants } from "../_constants";

const initState =
{
    error: null,
    loading: false,
    userId: null,
    requests:
    {
        content: []
    }
};

export function customerOrders ( state = initState, action )
{
    switch ( action.type ) 
    {

        case customerOrdersConstants.SET_ID:
            return {
                ...state,
                userId: action.id,
                requests:
                {
                    content: []
                }
            };

        case customerOrdersConstants.GET_ALL_BY_ID_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case customerOrdersConstants.GET_ALL_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case customerOrdersConstants.GET_ALL_BY_ID_SUCCESS:
            const req = { ...action.requests };
            const start = state.requests.content.length;

            req.content = req.content.map( ( item, i ) =>
            {
                const sn = i + 1 + start;
                return { ...item, sn };
            } );

            req.pageable.pageNumber += 1;

            if ( req.first )
            {

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };

            }
            else
            {

                req.content = [ ...state.requests.content, ...req.content ];

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };

            }


        default:
            return state;
    }
}
