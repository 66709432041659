import { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

const hasGAId = !!process.env.REACT_APP_GOOGLE_ANALYTICS;

if ( hasGAId )
{
  ReactGA.initialize( process.env.REACT_APP_GOOGLE_ANALYTICS );
}

function GAListener ( { children } ) 
{
  const location = useLocation();
  const history = useHistory();

  const sendPageView = ( location ) =>
  {
    ReactGA.set( { page: location.pathname } );
    ReactGA.pageview( location.pathname );
  };

  useEffect( () => 
  {
    if ( hasGAId )
    {
      sendPageView( location );
      history.listen( sendPageView );
    }
  }, [] );


  return children;
}

export default GAListener;
