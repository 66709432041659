export const tradeConstants =
{
    CREATE_TRADE_REQUEST: 'CREATE_TRADE_REQUEST',
    CREATE_TRADE_SUCCESS: 'CREATE_TRADE_SUCCESS',
    CREATE_TRADE_FAILURE: 'CREATE_TRADE_FAILURE',

    UPDATE_TRADE_REQUEST: 'UPDATE_TRADE_REQUEST',
    UPDATE_TRADE_SUCCESS: 'UPDATE_TRADE_SUCCESS',
    UPDATE_TRADE_FAILURE: 'UPDATE_TRADE_FAILURE',

    DELETE_TRADE_REQUEST: 'DELETE_TRADE_REQUEST',
    DELETE_TRADE_SUCCESS: 'DELETE_TRADE_SUCCESS',
    DELETE_TRADE_FAILURE: 'DELETE_TRADE_FAILURE',

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',
};
