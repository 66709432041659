import { baseUrl, authHeader } from '../_helpers';
import { handleResponse } from './interceptors';

function getAll ( page = 0 )
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch( `${ baseUrl }/api/admin/orders?page=${ page }`, requestOptions )
        .then( handleResponse )
        ;
}

function getOrderById ( id )
{
    if ( id )
    {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch( `${ baseUrl }/api/admin/orders/${ id }`, requestOptions )
            .then( handleResponse )
            ;

    }
}

function createOrder ( order ) 
{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( order ),
    };

    return fetch( `${ baseUrl }/api/admin/orders/create`, requestOptions )
        .then( handleResponse )
        ;
}

function sendCrypto ( orderId )
{
    if ( orderId )
    {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
        };

        return fetch( `${ baseUrl }/api/admin/orders/sendCrypto/${ orderId }`, requestOptions )
            .then( handleResponse )
            ;

    }
}

function updatePayment ( orderId, updatePaymentRequest ) 
{
    if ( orderId )
    {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify( updatePaymentRequest ),
        };

        return fetch( `${ baseUrl }/api/admin/orders/updatePayment/${ orderId }`, requestOptions )
            .then( handleResponse )
            ;

    }

}



function confirmDeposit ( confirmDepositRequest ) 
{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( confirmDepositRequest ),
    };


    return fetch( `${ baseUrl }/api/admin/orders/confirmdeposit/${ confirmDepositRequest.orderId }`, requestOptions )
        .then( handleResponse );
}
function payFiat ( orderId ) 
{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( { orderId } ),
    };


    return fetch( `${ baseUrl }/api/admin/orders/payfiat/${ orderId }`, requestOptions )
        .then( handleResponse );
}


export const ordersService =
{
    getAll,
    getOrderById,
    createOrder,
    sendCrypto,
    updatePayment,
    confirmDeposit,
    payFiat
};
