import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return  dispatch => {
        dispatch(success(message));
        setTimeout(() => {
            dispatch(clear())
          }, 5000)
    }
    function success(message) {  return { type: alertConstants.SUCCESS, message }}
    function clear() {  return { type: alertConstants.CLEAR }}

}

function error(message) {
    return dispatch => {
        dispatch(error(message));
        setTimeout(() => {
            dispatch(clear())
        }, 5000)
    }
    function error(message) {  return { type: alertConstants.ERROR, message}}
    function clear() {  return { type: alertConstants.CLEAR }}
}

function clear() {
    return { type: alertConstants.CLEAR };
}