import { buyRequestConstants } from '../_constants';
import { buyRequestService } from '../_services';


function getAll ( page )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const requests = await buyRequestService.getAll( page );
            dispatch( success( requests ) );
        } catch ( e )
        {
            // console.log( e );
            dispatch( failure( e.toString() ) );
        }

    };

    function request ()
    {
        return { type: buyRequestConstants.GETALL_REQUEST };
    }
    function success ( requests )
    {
        return { type: buyRequestConstants.GETALL_SUCCESS, requests };
    }
    function failure ( error )
    {
        return { type: buyRequestConstants.GETALL_FAILURE, error };
    }
}


export const buyRequestsActions =
{
    getAll
};
