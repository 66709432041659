import React, { useState, useEffect, createContext } from "react";
import { baseUrl } from '../_helpers';

const CurrencyContext = createContext();


function CurrencyProvider ( { children } ) 
{
    const [ coins, setCoins ] = useState( [] );
    const [ error, setError ] = useState( null );

    useEffect( () =>
    {
        const getData = async () =>
        {
            const coinsUrl = `${ baseUrl }/api/rates/exchange/buy`;

            try 
            {

                let coins = await fetch( `${ coinsUrl }` );

                if ( coins.ok )
                {
                    coins = await coins.json(); coins = coins.map( c => (
                        {
                            coin: c.crypto,
                            rate: +c.tradingPairPrice.price
                        }
                    ) );
                    setError( null );
                    setCoins( coins );
                }
                else
                {
                    setError( "Unable to retrieve coins" );
                }
            }
            catch ( error ) 
            {
                setError( error.mesage );
            }
        };

        getData();

        //update every 10 secs
        const timer = setInterval( getData, 10 * 1000 );

        return () => clearInterval( timer );


    }, [] );

    return (
        <CurrencyContext.Provider value={ { error, coins } } >
            {children }
        </CurrencyContext.Provider>
    );
}

export { CurrencyProvider, CurrencyContext };
