import logo from 'assets/img/logo/sidebar-logo.png';
import SourceLink from '../SourceLink';
import React from 'react';

import
{
  MdDashboard,
  MdExtension,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdAccountCircle,
  MdWeb,

} from 'react-icons/md';
import { FaMoneyBill, FaMoneyBillWave, FaExchangeAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import
{
  Nav,
  Navbar,
  Collapse,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const navItems2 = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/orders', name: 'orders', exact: true, Icon: MdWeb },
  {
    collapsible:
    {
      name: "settings",
      sub:
        [

          { to: '/users', name: 'users', exact: false, Icon: MdInsertChart },
          { to: '/trade-pairs', name: 'trade pairs', exact: false, Icon: FaExchangeAlt }
        ]
    }
  },
  {
    collapsible:
    {
      name: "request",
      sub:
        [

          { to: '/buy-request', name: 'buy requests', exact: false, Icon: FaMoneyBill },
          { to: '/sell-request', name: 'sell requests', exact: false, Icon: FaMoneyBillWave }
        ]
    }
  },
  { to: '/customer', name: 'customers', exact: false, Icon: MdAccountCircle }
];
const bem = bn.create( 'sidebar' );


class Sidebar extends React.Component
{
  state = {
    isOpenRequest: true,
    isOpenSettings: true,
  };

  handleClick = name => () =>
  {

    this.setState( prevState =>
    {
      const isOpen = prevState[ `isOpen${ name }` ];

      return {
        [ `isOpen${ name }` ]: !isOpen,
      };
    } );
  };

  generateNavMenus = navlist =>
  {
    const list = [];

    navlist.forEach( ( nav, index ) =>
    {
      if ( nav.collapsible )
      {
        const name = nav.collapsible.name[ 0 ].toUpperCase() + nav.collapsible.name.substr( 1 );

        const sublinks = nav.collapsible.sub;

        const menus = sublinks.map( ( { to, name, exact, Icon }, index ) => (
          <NavItem key={ index } className={ bem.e( 'nav-item' ) }>
            <BSNavLink
              id={ `navItem-${ name }-${ index }` }
              tag={ NavLink }
              to={ to }
              activeClassName="active"
              exact={ exact }
            >
              <Icon className={ bem.e( 'nav-item-icon' ) } />
              <span className="">{ name }</span>
            </BSNavLink>
          </NavItem>
        ) );

        const menuLink = (
          <React.Fragment key={ index }>
            <NavItem
              className={ bem.e( 'nav-item' ) }
              onClick={ this.handleClick( name ) }
            >
              <BSNavLink className={ bem.e( 'nav-item-collapse' ) }>
                <div className="d-flex">
                  <MdExtension className={ bem.e( 'nav-item-icon' ) } />
                  <span className=" align-self-start">{ name }</span>
                </div>
                <MdKeyboardArrowDown
                  className={ bem.e( 'nav-item-icon' ) }
                  style={ {
                    padding: 0,
                    transform: this.state[ `isOpen${ name }` ]
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  } }
                />
              </BSNavLink>
            </NavItem>

            <Collapse isOpen={ this.state[ `isOpen${ name }` ] }>
              { menus }
            </Collapse>

          </React.Fragment>
        );

        list.push( menuLink );

      }
      else
      {
        const { to, name, exact, Icon } = nav;

        list.push(
          <NavItem key={ index } className={ bem.e( 'nav-item' ) }>
            <BSNavLink
              id={ `navItem-${ name }-${ index }` }

              tag={ NavLink }
              to={ to }
              activeClassName="active"
              exact={ exact }
            >
              <Icon className={ bem.e( 'nav-item-icon' ) } />
              <span className="">{ name }</span>
            </BSNavLink>
          </NavItem>,
        );
      }
    } );

    return list;

  };

  render ()
  {
    return (
      <aside className={ bem.b() }>
        <div className={ bem.e( 'background' ) } />
        <div className={ bem.e( 'content' ) }>
          <Navbar className="pb-0">
            <SourceLink className="navbrand-link d-flex">
              <img src={ logo } className="pr-2 cr-logo" alt="Afriswap logo" />
            </SourceLink>
          </Navbar>
          <Nav className="mt-0" vertical>

            {
              this.generateNavMenus( navItems2 )
            }

          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
