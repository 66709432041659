import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { modalActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    register,
    createUser,
    editUser,
    getAll,
    delete: _delete
};

function login ( username, password )
{
    return async dispatch =>
    {

        try 
        {
            dispatch( request( { username } ) );
            const res = await userService.login( username, password );
            dispatch( success( res ) );
            localStorage.setItem( 'user', JSON.stringify( res ) );
            history.push( "/" );
        }
        catch ( error ) 
        {
            dispatch( failure( error.toString() ) );
            dispatch( alertActions.error( error.toString() ) );
        }



    };

    function request ( user ) { return { type: userConstants.LOGIN_REQUEST, user }; }
    function success ( user ) { return { type: userConstants.LOGIN_SUCCESS, user }; }
    function failure ( error ) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function logout ()
{
    userService.logout();

    history.push( '/login' );
    return { type: userConstants.LOGOUT };
}

function register ( user )
{
    return dispatch =>
    {
        dispatch( request( user ) );

        userService.register( user )
            .then(
                user =>
                {
                    dispatch( success() );
                    history.push( '/login' );
                    dispatch( alertActions.success( 'Registration successful' ) );
                },
                error =>
                {
                    dispatch( failure( error.toString() ) );
                    dispatch( alertActions.error( error.toString() ) );
                }
            );
    };

    function request ( user ) { return { type: userConstants.REGISTER_REQUEST, user }; }
    function success ( user ) { return { type: userConstants.REGISTER_SUCCESS, user }; }
    function failure ( error ) { return { type: userConstants.REGISTER_FAILURE, error }; }
}

function createUser ( user )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request( user ) );
            const res = await userService.create( user );
            dispatch( success( res ) );
            dispatch( modalActions.close() );
            dispatch( alertActions.success( 'User creation successful' ) );
        }
        catch ( e )
        {
            dispatch( failure( e.toString() ) );
            dispatch( alertActions.error( e.toString() ) );
        }

    };

    function request ( user ) { return { type: userConstants.CREATE_REQUEST, user }; }
    function success ( user ) { return { type: userConstants.CREATE_SUCCESS, user }; }
    function failure ( error ) { return { type: userConstants.CREATE_FAILURE, error }; }
}

function editUser ( user )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request( user ) );
            const res = await userService.update( user );
            dispatch( success( res ) );
            dispatch( alertActions.success( 'User has been edited successfully' ) );
        }
        catch ( e )
        {
            dispatch( failure( e.toString() ) );
            dispatch( alertActions.error( e.toString() ) );
        }

    };

    function request ( user ) { return { type: userConstants.UPDATE_REQUEST, user }; }
    function success ( user ) { return { type: userConstants.UPDATE_SUCCESS, user }; }
    function failure ( error ) { return { type: userConstants.UPDATE_FAILURE, error }; }
}

function getAll ( page )
{
    return async dispatch =>
    {
        try
        {
            dispatch( request() );
            const users = await userService.getAll( page );
            dispatch( success( users ) );
        } catch ( e )
        {
            dispatch( failure( e.toString() ) );
        }

    };

    function request () { return { type: userConstants.GETALL_REQUEST }; }
    function success ( users ) { return { type: userConstants.GETALL_SUCCESS, users }; }
    function failure ( error ) { return { type: userConstants.GETALL_FAILURE, error }; }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete ( id )
{
    return dispatch =>
    {
        dispatch( request( id ) );

        userService.delete( id )
            .then(
                user => dispatch( success( id ) ),
                error => dispatch( failure( id, error.toString() ) )
            );
    };

    function request ( id ) { return { type: userConstants.DELETE_REQUEST, id }; }
    function success ( id ) { return { type: userConstants.DELETE_SUCCESS, id }; }
    function failure ( id, error ) { return { type: userConstants.DELETE_FAILURE, id, error }; }
}
