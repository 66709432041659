import { buyRequestConstants } from '../_constants';

const initState =
{
    loading: false,
    requests:
    {
        content: []
    },
    error: null
};

export function buyRequests ( state = initState, action )
{
    switch ( action.type ) 
    {
        case buyRequestConstants.GETALL_REQUEST:
        case buyRequestConstants.GET_BY_ID_REQUEST:
        case buyRequestConstants.GET_BY_REF_ID_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case buyRequestConstants.GETALL_FAILURE:
        case buyRequestConstants.GET_BY_ID_FAILURE:
        case buyRequestConstants.GET_BY_REF_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case buyRequestConstants.GETALL_SUCCESS:
            const req = { ...action.requests };
            req.pageable.pageNumber += 1;

            const start = state.requests.content.length;

            req.content = req.content.map( ( item, i ) =>
            {
                const sn = i + 1 + start;
                return { ...item, sn };
            } );



            if ( req.first )
            {

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };


            }
            else
            {
                req.content = [ ...state.requests.content, ...req.content ];

                return {
                    ...state,
                    loading: false,
                    error: null,
                    requests: req
                };

            }


        default:
            return state;
    }
}
