export const customerOrdersConstants =
{

    GET_ALL_BY_ID_REQUEST: "GET_ALL_BY_ID_REQUEST",
    GET_ALL_BY_ID_SUCCESS: "GET_ALL_BY_ID_SUCCESS",
    GET_ALL_BY_ID_FAILURE: "GET_ALL_BY_ID_FAILURE",

    SET_ID: "SET_ID"

};
