import { baseUrl, authHeader } from '../_helpers';
import { handleResponse } from './interceptors';

const getTransactions = ( status ) =>
{
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch( `${ baseUrl }/api/admin/dashboard/transactionStats/${ status }`, requestOptions )
        .then( handleResponse )
        ;

};

export const dashboardService =
{
    getTransactions
};
